import {
  Typography,
  ControlledAutocompleteSelect,
  LoadingButton,
  ControlledTextField,
  ControlledPhoneInput,
} from 'design-system';
import { ReactComponent as RfqArt1 } from 'assets/images/rfq-arts/first-step-rfq-art.svg';
import { useSubmitRfqFirstStep } from './useSubmitRfqFirstStep';
import { UnitEnum } from 'services/types/generic/enums';
import { Stepper } from '../../components/stepper';
import { Box, Divider, Grid } from '@mui/material';
import styles from '../styles.module.scss';
import { Helmet } from 'react-helmet';

export const RfqFirstStep = () => {
  const { rfqIndustryItems, onSubmit, control, isValid, userEmail, loading, isTouchDevice, toListItems } =
    useSubmitRfqFirstStep();

  return (
    <>
      <Helmet>
        <title>Submit Your RFQ</title>
        <meta
          name="description"
          content="Customize your own request based on your business needs. Get competitive prices from our team"
        />
        <meta
          name="keywords"
          content="Easiest way to buy from africa, Buy African products, Buy cheapest ceramic, Sale on Ceramic, discounted african products, best african products, top b2b marketplace, business to business marketplace, b2b trading, best b2b marketplace, b2b manufacturers, manufacturers suppliers, business to business marketplace , suppliers and manufacturers, b2b exporters, marketplace suppliers, b2b marketplace food, manufacturers suppliers"
        />
      </Helmet>

      <Box className={styles.rfq__container}>
        {!isTouchDevice && (
          <Box className={styles.rfq__art}>
            <Typography variant="h6" className="text-violet">
              Just few steps to find your product.
            </Typography>
            <RfqArt1 />
            <Typography variant="subtitle2" className="text-midDarkGray pt-3">
              Provide the product details to meet your specific requirements.
            </Typography>
          </Box>
        )}

        <Box className={styles.rfq__form}>
          <Grid container spacing={0} className="justify-center w-full">
            <Grid item xs={12} md={9}>
              <Grid container spacing={0} className="justify-center w-full">
                <Grid item xs={12}>
                  <Stepper currentStep={1} />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Grid container spacing={{ xs: 1.5, md: 3 }}>
                    {isTouchDevice && (
                      <Grid item xs={12}>
                        <Typography variant="h6" className="text-violet">
                          Just a few steps to find your product.
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <ControlledAutocompleteSelect
                        name="industryId"
                        control={control}
                        options={rfqIndustryItems}
                        placeholder="Select product industry"
                        inputLabelProps={{ required: true, children: 'Product industry' }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ControlledTextField
                        name="productName"
                        control={control}
                        inputLabelProps={{ required: true, children: 'Product Name' }}
                        textFieldProps={{
                          placeholder: 'Enter or specify product name',
                          InputProps: { inputProps: { maxLength: 40 } },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        name="quantity"
                        control={control}
                        inputLabelProps={{ required: true, children: 'Quantity' }}
                        textFieldProps={{
                          placeholder: 'Enter the quantity',
                          InputProps: { inputProps: { min: 1, pattern: '\\d*' } },
                          type: 'number',
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledAutocompleteSelect
                        name="unit"
                        control={control}
                        options={toListItems(UnitEnum)}
                        placeholder="Select unit"
                        inputLabelProps={{ required: true, children: 'Unit' }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ borderColor: 'var(--lightGray)', paddingTop: '20px' }} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        name="email"
                        control={control}
                        inputLabelProps={{ required: !userEmail, children: 'Email', disabled: !!userEmail }}
                        textFieldProps={{
                          placeholder: 'Enter your email',
                          disabled: !!userEmail,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledPhoneInput
                        name="phone"
                        control={control}
                        inputLabelProps={{
                          required: true,
                          children: 'Phone Number',
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Box className={styles.button__container}>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      color="magentaPink"
                      className="w-full"
                      onClick={onSubmit}
                      disabled={!isValid}
                      loading={loading}
                      label="Next"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
