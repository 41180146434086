import { Typography, FileDropzone, Loader, LoadingButton, ControlledTextField } from 'design-system';
import { ReactComponent as RfqArt2 } from 'assets/images/rfq-arts/second-step-rfq-art.svg';
import { ReactComponent as UploadIcon } from 'design-system/public/icons/upload.svg';
import { useSubmitRfqSecondStep } from './useSubmitRfqSecondStep';
import { Stepper } from '../../components/stepper';
import styles from '../styles.module.scss';
import { Box, Grid } from '@mui/material';

export const RfqSecondStep = () => {
  const {
    setFiles,
    onSubmit,
    control,
    acceptedFileTypes,
    isTouchDevice,
    pageLoading,
    isPendingRfqUpdate,
    isPendingUploadFile,
  } = useSubmitRfqSecondStep();

  if (pageLoading) return <Loader />;
  return (
    <Box className={styles.rfq__container}>
      {!isTouchDevice && (
        <Box className={styles.rfq__art}>
          <Typography variant="subtitle2" className="text-midDarkGray pt-3">
            Do you have any specific requirement?!
          </Typography>
          <RfqArt2 />
          <Typography variant="h6" className="text-violet max-w-[358px] text-center">
            More data will help us provide you with the best experience
          </Typography>
        </Box>
      )}

      <Box className={styles.rfq__form}>
        {/* <Box className={styles.content__container}> */}
        {/* <Stepper currentStep={2} /> */}

        {/* 
          <Box className={styles.upload__container}>
            <Typography variant="subtitle1" className="text-darkGray pb-2">
              Upload files
            </Typography>

            <FileDropzone
              multiple={true}
              maxSize={5 * 1024 * 1024}
              acceptedFileTypes={acceptedFileTypes}
              setFiles={setFiles}
              icon={<UploadIcon width={isTouchDevice ? 24 : 38} height={isTouchDevice ? 24 : 38} />}
              helperText="or select max of 5 files to be uploaded with max size of 5MB each (supported types: .txt, .doc, .docx, .xls, xlsx, .pdf, .jpg, .jpeg, .png, .csv)"
              maxFiles={5}
            />
          </Box>

          <ControlledTextField
            name="description"
            control={control}
            inputLabelProps={{ children: 'Detailed requirements' }}
            textFieldProps={{
              placeholder: "I'm looking for...",
              multiline: true,
              rows: 4,
            }}
          />

          <Box className={styles.button__container}>
            <LoadingButton
              size="large"
              variant="contained"
              color="magentaPink"
              className="w-full"
              onClick={onSubmit}
              loading={isPendingUploadFile || isPendingRfqUpdate}
              label="Next"
            />
          </Box> */}
        {/* </Box> */}

        <Grid container spacing={0} className="justify-center w-full">
          <Grid item xs={12} md={9}>
            <Grid container spacing={0} className="justify-center w-full">
              <Grid item xs={12}>
                <Stepper currentStep={2} />
              </Grid>

              <Grid item xs={12} md={10}>
                <Grid container spacing={{ xs: 1.5, md: 3 }}>
                  {isTouchDevice && (
                    <Grid item xs={12}>
                      <Typography variant="h6" className="text-violet">
                        More data will help us provide you with the best experience
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Box className={styles.upload__container}>
                      <Typography variant="subtitle1" className="text-darkGray pb-2">
                        Upload files
                      </Typography>

                      <FileDropzone
                        multiple={true}
                        maxSize={5 * 1024 * 1024}
                        acceptedFileTypes={acceptedFileTypes}
                        setFiles={setFiles}
                        icon={<UploadIcon width={isTouchDevice ? 24 : 38} height={isTouchDevice ? 24 : 38} />}
                        helperText="or select max of 5 files to be uploaded with max size of 5MB each (supported types: .txt, .doc, .docx, .xls, xlsx, .pdf, .jpg, .jpeg, .png, .csv)"
                        maxFiles={5}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <ControlledTextField
                      name="description"
                      control={control}
                      inputLabelProps={{ children: 'Detailed requirements' }}
                      textFieldProps={{
                        placeholder: "I'm looking for...",
                        multiline: true,
                        rows: 4,
                      }}
                    />
                  </Grid>
                </Grid>

                <Box className={styles.button__container}>
                  <LoadingButton
                    size="large"
                    variant="contained"
                    color="magentaPink"
                    className="w-full"
                    onClick={onSubmit}
                    loading={isPendingUploadFile || isPendingRfqUpdate}
                    label="Next"
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
