import { Typography, ControlledAutocompleteSelect, Loader, LoadingButton, ControlledTextField } from 'design-system';
import { ReactComponent as RfqArt3 } from 'assets/images/rfq-arts/third-step-rfq-art.svg';
import { ShipmentTermEnum } from 'services/types/generic/enums';
import { useSubmitRfqThirdStep } from './useSubmitRfqThirdStep';
import { Stepper } from 'pages/rfq/components/stepper';
import { Box, Divider, Grid } from '@mui/material';
import { countries, toListItems } from 'utils';
import styles from '../styles.module.scss';

export const RfqThirdStep = () => {
  const {
    onSubmit,
    control,
    states,
    getValues,
    isValid,
    pageLoading,
    isPendingRfqUpdate,
    paymentTerms,
    isTouchDevice,
  } = useSubmitRfqThirdStep();

  document.querySelector('#hubspot-messages-iframe-container')?.classList.add('translate-x-full');
  if (pageLoading) return <Loader />;
  return (
    <>
      <Box className={styles.rfq__container}>
        {!isTouchDevice && (
          <Box className={styles.rfq__art}>
            <Typography variant="h6" className="text-violet">
              Great, you have reached your final step!
            </Typography>
            <RfqArt3 />
            <Typography variant="subtitle2" className="text-midDarkGray pt-3 max-w-[358px] text-center">
              Please choose your preferred shipping method and click submit.
            </Typography>
          </Box>
        )}

        <Box className={styles.rfq__form}>
          <Grid container spacing={0} className="justify-center w-full">
            <Grid item xs={12} md={9}>
              <Grid container spacing={0} className="justify-center w-full">
                <Grid item xs={12}>
                  <Stepper currentStep={3} />
                </Grid>

                <Grid item xs={12} md={10}>
                  <Grid container spacing={{ xs: 1.5, md: 3 }}>
                    {isTouchDevice && (
                      <Grid item xs={12}>
                        <Typography variant="h6" className="text-violet">
                          Great, you have reached your final step!
                        </Typography>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <ControlledAutocompleteSelect
                        name="shipmentTerm"
                        control={control}
                        options={toListItems(ShipmentTermEnum)}
                        placeholder="Select shipping terms"
                        inputLabelProps={{ required: true, children: 'Shipping Terms' }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ControlledAutocompleteSelect
                        name="country"
                        control={control}
                        options={countries}
                        placeholder="Select shipping destination"
                        inputLabelProps={{ required: true, children: 'Shipping Destination' }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ControlledAutocompleteSelect
                        name="state"
                        control={control}
                        options={states}
                        disabled={!getValues('country')}
                        placeholder="Select shipping state"
                        inputLabelProps={{
                          required: true,
                          children: 'Shipping State',
                          disabled: !getValues('country'),
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <ControlledTextField
                        // changed from address to adrs to prevent browser autocomplete
                        name="adrs"
                        control={control}
                        inputLabelProps={{ required: true, children: 'Full Address' }}
                        textFieldProps={{
                          placeholder: 'Enter your full delivery information',
                          InputProps: { inputProps: { maxLength: 125 } },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider sx={{ borderColor: 'var(--lightGray)', paddingTop: '20px' }} />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledTextField
                        name="targetUnitPrice"
                        control={control}
                        inputLabelProps={{ required: true, children: 'Target Unit Price' }}
                        textFieldProps={{
                          placeholder: 'Enter the price you prefer / unit',
                          type: 'number',
                          InputProps: {
                            inputProps: { min: 1, pattern: '\\d*' },
                            endAdornment: (
                              <Box className="flex items-center h-12 bg-white pe-3">
                                <Divider
                                  orientation="vertical"
                                  className="mr-2 h-8"
                                  sx={{ borderColor: 'var(--lightGray)' }}
                                />
                                <Typography variant="caption">USD</Typography>
                              </Box>
                            ),
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <ControlledAutocompleteSelect
                        name="paymentTerms"
                        control={control}
                        options={paymentTerms}
                        inputLabelProps={{ required: true, children: 'Payment Terms' }}
                        placeholder="Choose the payment terms you prefer"
                      />
                    </Grid>
                  </Grid>

                  <Box className={styles.button__container}>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      color="magentaPink"
                      className="w-full"
                      onClick={onSubmit}
                      disabled={!isValid}
                      loading={isPendingRfqUpdate}
                      label="Next"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
