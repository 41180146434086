import { ReactComponent as StepSuccess } from 'design-system/public/icons/step-success.svg';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { StepDivider } from '../step-divider';
import { Typography } from 'design-system';
import styles from './styles.module.scss';

export const Stepper = ({ currentStep }: { currentStep: number }) => {
  const isDownMd = useMediaQuery(useTheme().breakpoints.down('md'));
  return (
    <Box className="flex justify-between items-center gap-1 mb-6 max-sm:mb-5">
      <Typography variant="subtitle2">
        <Box className={`${styles.step} ${currentStep === 1 && styles.active} ${currentStep < 1 && styles.disabled}`}>
          {currentStep > 1 && <StepSuccess className={styles.success__icon} />}
          {currentStep === 1 && <Box className={styles.step__circle}>1</Box>}
          {!(isDownMd && currentStep !== 1) && 'Information'}
        </Box>
      </Typography>

      <StepDivider isActive={currentStep > 1} />

      <Typography variant="subtitle2">
        <Box className={`${styles.step} ${currentStep === 2 && styles.active} ${currentStep < 2 && styles.disabled}`}>
          {currentStep > 2 && <StepSuccess className={styles.success__icon} />}
          {currentStep <= 2 && <Box className={styles.step__circle}>2</Box>}
          {!(isDownMd && currentStep !== 2) && 'Upload Files'}
        </Box>
      </Typography>

      <StepDivider isActive={currentStep > 2} />

      <Typography variant="subtitle2">
        <Box className={`${styles.step} ${currentStep === 3 && styles.active} ${currentStep < 3 && styles.disabled}`}>
          {currentStep <= 3 && <Box className={styles.step__circle}>3</Box>}
          {!(isDownMd && currentStep !== 3) && 'Shipping & Payment'}
        </Box>
      </Typography>
    </Box>
  );
};
